<template>
	<secondary-page-layout :title="title" :info-link="true" :product="product">
		<div class="agreement-item" v-if="!showVerify">
			<div class="head-agreement" v-if="agreement">
				<h1 class="title">
		          <span v-if="['2225', '3016', '3014'].includes(agreement.insrType)">
		          {{ $t('monthlyPayment') }}
		          </span>
		          <span v-else>
		             {{ $t('Сумма страховки') }}
		          </span>
        		</h1>
				<p class="price">{{agreement.amount}} ₸</p>
			</div>

			<v-dialog width="300" content-class="alert_change-card"/>

			<div class="info-card arg new">
				<div class="item">
					<div class="title-wrap">
						<div class="icon">
							<icon icon-name="calendar-icon" class="text-black"></icon>
						</div>
						<p>{{ $t('paymentDateNew') }}</p>
					</div>
					<p class="info" v-if="agreement">{{ beginDate }}</p>
				</div>
				<div class="item">
					<div class="title-wrap">
						<div class="icon">
							<icon icon-name="calendar-icon"></icon>
						</div>
						<p>Дата следующего  платежа</p>
					</div>
					<p class="info" v-if="agreement">{{endDate}} </p>
				</div>
			</div>

			<div class="navigate-agreement">
			
				<div class="wrap-items" v-if="agreement">
					<router-link :to="{name: 'change.tariff', params: { agreement: policyId }}" class="link-item" v-if="isActive">
						<div class="icon-block">
					        <icon icon-name="my-accs"></icon>
					    </div>
						<p>{{ $t('changeTariff') }}</p>
					</router-link>

	          		<a @click="openChangeCard" class="link-item" v-if="isActive">
						<div class="icon-block">
					        <icon icon-name="credit-card"></icon>
					    </div>
						<p>{{ $t('changeMap') }}</p>
					</a>

			       	<router-link :to="{name: 'sos.instruction', params: { id: sosId }}" class="link-item" v-if="isActive">
			            <div class="icon-block">
			              <icon icon-name="my-accs"></icon>
			            </div>
			            <p>Подать на выплату</p>
			        </router-link>

              <router-link class="link-item" :to="{name: 'agreements.pdf', params: {agreement: agreement.id}, query: {title}}">
                <div class="icon-block">
                  <icon icon-name="download"></icon>
                </div>
                <p>Скачать</p>
              </router-link>

					<a @click="openUnsubscribe" class="link-item" v-if="isActive">
							<div class="icon-block">
						        <icon icon-name="close-green"></icon>
						    </div>
							<p>{{ $t('cancelSubscription') }}</p>
					</a>
					<a class="link-item">
					</a>
				</div>
			</div>
			<ul class="list-agreement" v-if="agreement">
				<li class="item">
					<span class="title">{{ $t('Программа') }}</span>
					<span class="info">“{{agreement.productName}}”</span>
				</li>
		        <li class="item" v-if="['2225', '3014', '3016'].includes(agreement.insrType)">
		          <span class="title">{{ $t('Тариф') }}</span>
		          <span class="info">“{{ agreement.tariff }}”</span>
		        </li>
				<li class="item">
					<span class="title">Страхователь</span>
					<span class="info">{{ agreement.holder.title }}</span>
				</li>
				<template v-for="(item, index) in agreement.objects" v-if="agreement.objects">
					<!-- Номера машин-->
					<template v-if='["2225"].includes(agreement.insrType)'>
						<li class="item">
		          			<span class="title">Под защитой</span>
							<span class="info">{{ item.license_plate }} {{ item.model }} {{ item.title }}</span>
		          		</li>
		          	</template>

					<!--Адрес-->
					<template v-if='["3016", "3014"].includes(agreement.insrType)'>
						<li class="item">
		          			<span class="title">Под защитой</span>
							<span class="info">{{ item.street_name}} {{ item.blockNumber }}<span v-if="item.apartmentNumber">, {{ item.apartmentNumber }}</span></span>
		          		</li>
		          	</template>
				</template>
        <template v-if='["3014"].includes(agreement.insrType)'>
          <li class="item">
            <span class="title">Возместим Вам до</span>
            <span class="info">{{ agreement.coverValue/2 | priceFormat }} ₸</span>
          </li>
          <li class="item">
            <span class="title">Возместим соседям до</span>
            <span class="info">{{ agreement.coverValue/2 | priceFormat }} ₸</span>
          </li>
        </template>
        <template v-if='["3016"].includes(agreement.insrType)'>
          <li class="item">
            <span class="title">Возместим Вам до</span>
            <span class="info">{{ agreement.coverValue | priceFormat }} ₸</span>
          </li>
        </template>
        <template v-else-if='["2225"].includes(agreement.insrType)'>
          <li class="item">
            <span class="title">Выплата Вам</span>
            <span class="info">{{ agreement.coverValue | priceFormat }} ₸</span>
          </li>
        </template>

			</ul>
		</div>
		<otp-verify v-else v-model="code" @submit="verifyCode" @sendCode="sendConfirmCode" :error-message="errorMessage"></otp-verify>
	</secondary-page-layout>
</template>
<script>
	import ChangeTariffModel from "@/components/Modals/ChangeTariffModel";
	import UnsubscribeModal from "@/components/Modals/UnsubscribeModal";
	import {mapGetters, mapActions} from "vuex";
	import OtpVerify from "@/components/OTP/OtpVerify";
	import {generateUnsubscribeOtp, verifyUnsubscribeOtp} from "@/api/otp";
	import moment from "moment";
  import ePayPayment from "../../mixin/ePayPayment";

	export default {
		name: "AgreementItem",
		data() {
		    return {
		      	product: {},
		      	data_success:{
			        title: this.$i18n.t('paymentCardSuccessfully_1'),
			        subtitle: this.$i18n.t('paymentCardSuccessfully_2'),
			    },
			    agreement: null,
			    type: null,
			    code: '',
      			errorMessage: null,
    	    }
		},
		components:{
	  		OtpVerify
	  	},
	  	mounted(){

	  	},
    mixins: [ePayPayment],
		computed: {
			...mapGetters({
          	authUser: 'authUser',
          	accessToken: 'accessToken',
          	comment: 'itemSubscription/comment',
          	rating: 'itemSubscription/rating',
          	showVerify: 'itemSubscription/showVerify',
          	paramOtp: 'itemSubscription/paramOtp',
      	}),
      sosId() {
        if (['1000','2220','2221','2225'].includes(this.agreement.insrType)){
          return 1;
        } else if (['3011','3012', '3014', '3016', '3017', '3019'].includes(this.agreement.insrType)) {
          return 2;
        } else if (['9100'].includes(this.agreement.insrType)) {
          return 3;
        } else if (['6230', '6231'].includes(this.agreement.insrType)) {
          return 4
        } else if (['6610', '6611'].includes(this.agreement.insrType)) {
          return 5
        }
      },
      apiUrl(){
        return process.env.VUE_APP_BACKEND_URL
      },
     
      title() {
        if(this.agreement){
          return this.agreement.productName;
        }else{
          return '';
        }
      },
      isActive(){
      	return +this.$route.params.active;
      },
      policyId(){
        return this.$route.params.agreement || '';
      },
      beginDate() {
        if(this.agreement){
          return moment(this.agreement.beginDate.date).subtract(2, "days").format("DD.MM.YYYY");
        }else{
          return '';
        }
      },
      endDate() {
        if(this.agreement){
          return moment(this.agreement.endDate.date).format("DD.MM.YYYY");
        }else{
          return '';
        }
      },
		},
		created() {
			this.setDefaultInfo();
		    this.getProduct();
		    this.clearUnsubscribe();
		},
		methods: {
			...mapActions({
		      changePhoneNumber: 'itemSubscription/changePhoneNumber',
		      changeHolder: 'itemSubscription/changeHolder',
		      changeInsureds: 'itemSubscription/changeInsureds',
		      changeTariff: 'itemSubscription/changeTariff',
		      changeManId: 'itemSubscription/changeManId',
		      changeItemSub: 'itemSubscription/changeItemSub',
		      clearUnsubscribe: 'itemSubscription/clearUnsubscribe',
      }),
      async downloadAgreement() {
        let loader = this.$loading.show({});
        try {
          await window.axios.get(`policies/download/${this.agreement.id}?key=${this.agreement.key}`)
          loader.hide()
          let fileLink = document.createElement('a');

          fileLink.href = `${this.apiUrl}/storage/${this.agreement.id}.pdf`;

          fileLink.click();
        } catch (err) {
          throw err
        }
      },
			async setDefaultInfo(){
				const loader = this.$loading.show();
				const {data} = await window.axios.get(`policies/${this.$route.params.agreement}`);
		      	this.agreement = data;
		      	this.changePhoneNumber(this.agreement.holder.contacts.MOBILE);
		      	this.changeHolder(this.agreement.holder);
		      	this.changeItemSub(this.agreement);
		      	if(this.agreement.insureds){
		      		this.changeInsureds(this.agreement.insureds);
		      	}
		      	
		      	loader.hide();
			},
      async getProduct() {
        const {data} = await window.axios.get(`/insurance-products/${this.$route.params.id}`);
        this.product = data;
      },
			openChangeCard(){
				this.$modal.show('dialog', {
			        title: this.$i18n.t('loyalClientModalTitle'),
			        text:  this.$i18n.t('yourCardDebited'),
			        buttons: [{
			          title: this.$i18n.t('good'),
			          handler: () => {
			          	this.$modal.hide('dialog');
			          	this.changeCard();
			            return null;
			          }
			        },
			        {
			          title: this.$i18n.t('cancel'),
			          handler: () => {
			            this.$modal.hide('dialog')
			            return null;
			          }
			        },
			        ]
			    });
			},
			//  Сменить карту
			async changeCard() {
		    	try{
		    		let loader = this.$loading.show();

            const { data } = await window.axios.post(`/subscription/auth-change-card`, {
              policy_id: this.agreement.id
            })

            await this.goToPaymentPage(data, this.authUser)
            loader.hide();
				}catch(e){
		    		console.log(e);
		    	}
		    },
			openUnsubscribe(){
				this.$modal.show(UnsubscribeModal,{ product_id: this.product.id }, this.$modalConfig);
			},
			async sendConfirmCode() {
				//generate Otp
		      const loader = this.$loading.show();
		      
		      try {
		        let data = await generateUnsubscribeOtp(this.paramOtp, this.policyId);
		       	
		      } catch (e) {
		        throw e
		      } finally {
		        loader.hide();
		      }
		    },
			async verifyCode(code) {
				//check Otp
				let loader = this.$loading.show();
		      	try {
		      		
		        	await verifyUnsubscribeOtp(this.paramOtp, this.policyId, code);
		        	await this.setUnsubscribe();
		      	} catch (err) {
			        const { data, status } = err.response
			        if (status === 400) {
			          this.errorMessage = data.message;
			        }
		      	} finally {
		        	loader.hide()
		      	}
		    },
			async setUnsubscribe(){
				let loader = this.$loading.show();
	  			try {
	  				//комментарий отправить
	  				const { data_comment } = await window.axios.post(`/feedback`,{
	  					rating: this.rating,
	  					user_id: this.authUser.id,
	  					product_id: this.product.id,
	  					comment: this.comment,
	  				});

            await window.axios.delete(`/subscription/${this.agreement.id}`);

  					loader.hide();
            let data_success = {
               title: this.$i18n.t('canceledSubscription_1'),
                subtitle: this.$i18n.t('canceledSubscription_2'),
            }
            this.$router.push({name: 'agreement.verify', params: { data_success: data_success} });
			    } catch (err) {
			    	console.log('error', err);
			        this.onError({
			          title: `Ошибка`,
			          message: (err?.response?.data?.message || err?.message),
			          code: err?.response?.data?.error_code || 0
			        })

			        loader.hide();
			    }
			}
		},
		watch: {
	         showVerify(value) {
	         	if(value){
	            	this.sendConfirmCode();
	            }
	        }
	    },
	}
</script>
<style lang="scss">
	.agreement-item{
		padding: 0 16px;
		.head-agreement{
			padding: 0px 24px;
			margin-bottom: 16px;
			.title{
				font-family: 'Manrope';
				font-weight: 500;
				font-size: 12px;
				line-height: 16px;
				color: #566681;
				margin-bottom: 16px;
			}
			.price{
				font-family: 'Manrope';
				font-weight: 800;
				font-size: 40px;
				line-height: 48px;
				color: #071222;
				margin-bottom: 0px;
			}
		}


		.info-card.arg{
			background: #EDEDED;
			border-radius: 12px;
			padding: 14px 0px;
			display: flex;
			justify-content: center;
			margin-bottom: 16px;
			&.new{
				.item{
					&:first-child{
						margin-right: 0px;
					}
					&:last-child{
						margin-left: 5px;
					}
				}
			}
			.item{
				&:first-child{
					margin-right: 20px;
				}
				&:last-child{
					margin-left: 20px;
				}
				.info{
					color: #071222;
					font-family: 'Manrope';
					font-weight: 700;
					font-size: 12px;
					line-height: 16px;
					text-align: center;
					margin-bottom: 0px;
				}
				.title-wrap{
					display: flex;
					align-items: center;
					margin-bottom: 4px;
					padding: 0px;
					p{
						font-family: 'Manrope';
						font-style: normal;
						font-weight: 500;
						font-size: 12px;
						line-height: 16px;
						color: #566681;
						margin-bottom: 0px;
					}

					.icon{
						width: 16px;
						height: 16px;
						border-radius: 50%;
						margin-right: 4px;
						display: flex;
						justify-content: center;
						align-items: center;
						background: #E1F4EE;
						padding: 2px;
						&.red{
							background: #F8DFE8;
						}
					}
				}
			}
		}
		.navigate-agreement{
      padding: 12px 24px;
			background: #FFFFFF;
			box-shadow: 0px 12px 16px rgba(0, 0, 0, 0.05);
			border-radius: 12px;
			margin-bottom: 30px;

			.wrap-items{
				display: flex;
        flex-wrap: wrap;
				padding-bottom: 12px;
				&:nth-child(2){
					margin-top: 32px;
				}
			}
			.link-item{
				display: flex;
        flex-direction: column;
        align-items: center;
        flex: 0 0 33.333333%;
        margin: 1rem 0;
        .icon-block{
					margin-bottom: 4px;
				}
				p{
					font-family: 'Manrope';
					font-weight: 500;
					font-size: 12px;
					line-height: 16px;
					text-align: center;
					color: #071222;
					margin-bottom: 0px;
				}
			}
			.empty-item{
				display: flex;
    			width: 20%;
			}
		}


	}
</style>
